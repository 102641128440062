'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.dispatch.factory:DispatchGroupsManager

 # @description

###
angular
  .module 'mundoAdmin.dispatch'
  .factory 'DispatchGroupsManager', [
    'MundoDefaultManager'
    'TenantManager'
    (
      MundoDefaultManager
      TenantManager
    )->
      DispatchGroupsManagerBase = new MundoDefaultManager()

      DispatchGroupsManagerBase.setUrl('lpa/dispatch/groups')
      DispatchGroupsManagerBase.setAutoTenant()
      DispatchGroupsManagerBase.setNewObject(['label', 'code', 'weight'])
      DispatchGroupsManagerBase.setUpdateObject(['label', 'code', 'weight'])
      DispatchGroupsManagerBase.setSearchFields(['label', 'code', 'tenant.label'])

      DispatchGroupsManagerBase.getNewForm = ->
        [
          key: 'label'
          name: 'label'
          type: 'input'
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
        ,
          key: 'code'
          name: 'code'
          type: 'input'
          templateOptions:
            label: 'Code'
            placeholder: 'code'
            required: true
        ,
          key: 'weight'
          name: 'weight'
          type: 'input'
          templateOptions:
            label: 'Gewicht'
            placeholder: ''
            required: false
        ]

      DispatchGroupsManagerBase.getEditForm = (data) ->
        [
          key: 'label'
          name: 'label'
          type: 'input'
          defaultValue: data.label
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
        ,
          key: 'code'
          name: 'code'
          defaultValue: data.code
          type: 'input'
          templateOptions:
            label: 'Code'
            placeholder: 'code'
            required: true
        ,
          key: 'weight'
          name: 'weight'
          type: 'input'
          defaultValue: data.weight
          templateOptions:
            label: 'Gewicht'
            placeholder: ''
            required: false
        ]

      DispatchGroupsManagerBase.getListSchema = ->
        [
          key: 'label'
          title: 'datatable.label.label'
          sort: 'label'
        ,
          key: 'code'
          title: 'datatable.label.code'
          sort: 'code'
        ,
          key: 'weight'
          title: 'datatable.label.weight'
          sort: 'weight'
      ]

      #permission needed to edit/delete an entity
      DispatchGroupsManagerBase.editPermission = 'manage all MundoMosaLpaDispatchingBundle:DispatchGroup entities'

      DispatchGroupsManagerBase
  ]
